import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCollapse } from 'react-collapsed'
import FeatureSubCategory from './FeatureSubCategory';
import Feature from './Feature';
import { HomeFeaturesContext } from './contexts';



const FeatureCategory = (props) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const homeFeatures = useContext(HomeFeaturesContext).homeFeatures

  const subCategories = () => {
    const orderedSubCategories = props.featureCategory.sub_categories.sort((a, b) => a.weight - b.weight);
    return orderedSubCategories.map((subCategory) => {
      return (
        <div key={subCategory.id}>
          <FeatureSubCategory featureSubCategory={subCategory} />
        </div>
      );
    });
  }

  const getCategoryFeatures = (featureCategory) => {
    return homeFeatures.filter((homeFeature) => {
      return homeFeature.feature.category_id === featureCategory.id;
    });
  }

  const getAllSubCategoryFeatures = (featureCategory) => {
    let allFeatures = [];
    
    // Get features directly in this category
    const directFeatures = getCategoryFeatures(featureCategory);
    allFeatures = allFeatures.concat(directFeatures);

    // Get features from all sub-categories
    featureCategory.sub_categories.forEach(subCategory => {
      const subCategoryFeatures = homeFeatures.filter(homeFeature => 
        homeFeature.feature.category_id === subCategory.id
      );
      allFeatures = allFeatures.concat(subCategoryFeatures);
    });
    
    // Sum up the total number of active components across all features
    const totalActiveComponents = allFeatures.reduce((sum, homeFeature) => {
      return sum + (homeFeature.active_components?.length || 1);
    }, 0);
    
    return totalActiveComponents;
  }


  const features = () => {
    const matchingFeatures = getCategoryFeatures(props.featureCategory);
    return matchingFeatures.map((matchingFeature) => {
      return (
        <div key={matchingFeature.id}>
          <Feature homeFeature={matchingFeature} />
        </div>
      );
    });
  }

  return (
    <div class="category" {...getToggleProps()}>
      <div>
        <div class="header">
          <div class="collapsible-control">
            <i class={`fa-solid ${isExpanded ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
            <h2 className="category-title">{ props.featureCategory.name }</h2> &nbsp; - &nbsp;
            <small>
              { getAllSubCategoryFeatures(props.featureCategory) } components selected
            </small>
          </div>
          <div>
            
          </div>
        </div>

        <div {...getCollapseProps()}>
          <div class="feature-category">
            {features()}
            <div className="feature-subcategories">
              {subCategories()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


FeatureCategory.propTypes = {
  featureCategory: PropTypes.array.isRequired,
};


export default FeatureCategory;
