import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    // Find the note form element (assuming it has an ID or class)
    const noteForm = document.querySelector('[data-notes-form]')
    const noteFormButton = document.querySelector('[data-action="click->notes#toggle"]')
    
    // Toggle the visibility of the form
    if (noteForm) {
      noteForm.classList.toggle('is-hidden')
      noteFormButton.classList.toggle('is-hidden')
    }
  }
}
