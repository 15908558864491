import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Feature from './Feature';
import { HomeFeaturesContext } from './contexts';

const FeatureSubCategory = (props) => {
  const homeFeatures = useContext(HomeFeaturesContext).homeFeatures

  const matchingFeatures = homeFeatures.filter((homeFeature) => {
    return homeFeature.feature.category_id === props.featureSubCategory.id;
  });

  const features = () => {
    const orderedFeatures = matchingFeatures.sort((a, b) => a.weight - b.weight);
    return orderedFeatures.map((homeFeature) => {
      return (
        <div key={homeFeature.id}>
          <Feature homeFeature={homeFeature} />
        </div>
      );
    });
  }

  return (
    <div>
      {/* <h3>{ props.featureSubCategory.name }</h3> */}
      {features()}
      {/* { matchingFeatures.length === 0 && 
        <p>None</p>
      } */}
    </div>
  );
};


FeatureSubCategory.propTypes = {
  featureSubCategory: PropTypes.object.isRequired,
};


export default FeatureSubCategory;
